import styled, { css } from 'styled-components';

const Container = styled.div`
  ${(props) => props.overflow && css`
    padding-left: ${props.overflow}px;
  `};

  ${(props) => props.background && css`
    background: ${props.background};
  `};
`;

const CollapsibleWraper = styled.div`
  margin-bottom: 16px;
  border-radius: 7px;
  overflow: hidden;  
`;

export { Container, CollapsibleWraper };
