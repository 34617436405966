import styled, { css } from 'styled-components';

import { colors, sider } from '../../utils/variables.styles';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 57px;
  background: ${colors.main};
  padding-left: 12px;
  transition: ${sider.transitionLength}s;
  border-radius: 7px;
  
  ${(props) => props.collapsed && css`
    padding-left: 16px;
  `}

  ${(props) => props.pointer && css`
    cursor: pointer;
  `}

  ${(props) => props.bottom && css`
    @media (max-width: 1000px) {
      position: relative;
      overflow: hidden;
    }
  `}
`;

const IconWrapper = styled.div`
  ${(props) => !props.collapsed && css`
    margin-right: 15px;
  `}
`;

export {
  Container,
  IconWrapper
};
