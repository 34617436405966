import styled from 'styled-components';
import { colors } from '../../utils/variables.styles';

const StyledImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
  background: ${colors.gray};
`;

const Container = styled.div`
  background: ${colors.mainLight};
  padding: 10px 24px;
`;

const ButtonWrapper = styled.div`
  padding-top: 15px;
`;

export {
  StyledImg,
  Container,
  ButtonWrapper
};
