import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Select, Spin, Icon } from 'antd';
import EditWrapper from '../EditWrapper';

import { open, close } from './SelectEdit.reducer';
import selector from './SelectEdit.selector';

import {
  Content,
  StyledTitle,
  SelectedWrapper,
  Container
} from './SelectEdit.styles';

import { optionShape } from './SelectEdit.types';
import { infoShape } from '../../types/info.types';
import { colors } from '../../utils/variables.styles';

class SelectEdit extends React.Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    open: PropTypes.func.isRequired,
    info: infoShape,
    isEdit: PropTypes.bool,
    loading: PropTypes.bool,
    nested: PropTypes.bool,
    opened: PropTypes.number,
    options: PropTypes.arrayOf(optionShape),
    selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    onChange: PropTypes.func,
    onEditClick: PropTypes.func
  };

  static defaultProps = {
    info: null,
    nested: false,
    selected: null,
    onChange: () => {},
    onEditClick: null,
    loading: false,
    options: [],
    title: null,
    opened: null,
    isEdit: false
  };

  id = Math.random();

  toggleEdit = () => {
    if (this.id === this.props.opened) {
      this.props.close();
    } else {
      this.props.open(this.id);
    }
  }

  render() {
    const isOpened = this.props.opened === this.id;

    return (
      <Spin spinning={this.props.loading}>
        <Container nested={this.props.nested}>
          <EditWrapper
            info={this.props.info}
            isEdit={isOpened}
            onEditClick={this.props.onEditClick ? this.props.onEditClick : this.toggleEdit}
            noEdit={
              (this.props.loading
              || (this.props.options && this.props.options.length <= 1)
              ) && !this.props.isEdit
            }
            nested={this.props.nested}
          >
            <Content>
              {
                !this.props.loading ? (
                  <React.Fragment>
                    <StyledTitle>{this.props.title}{this.props.options.length > 0 && ':'}</StyledTitle>
                    {
                      isOpened ? (
                        <div style={{ width: '100%' }}>
                          <Select
                            suffixIcon={<Icon type="caret-down" fill={colors.selectArrow} className="custom-chevron" />}
                            style={{ width: '100%' }}
                            defaultValue={this.props.selected}
                            onChange={this.props.onChange}
                            onDeselect={() => { this.props.close(); }}
                            onBlur={() => { this.props.close(); }}
                            onSelect={this.toggleEdit}
                          >
                            {
                              this.props.options && this.props.options.map((el) => (
                                <Select.Option key={el.id} value={el.id}>{el.value}</Select.Option>
                              ))
                            }
                          </Select>
                        </div>
                      ) : (
                        this.props.options.length > 0 && (
                          <SelectedWrapper>
                            {
                              this.props.selected ? (
                                this.props.options.find((el) => el.id === this.props.selected).value
                              ) : 'Brak'
                            }
                          </SelectedWrapper>
                        )
                      )
                    }
                  </React.Fragment>
                ) : null
              }
            </Content>
          </EditWrapper>
        </Container>
      </Spin>
    );
  }
}

const mapDispatchToProps = {
  open,
  close
};

export default connect(selector, mapDispatchToProps)(SelectEdit);
