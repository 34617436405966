import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, Modal } from 'antd';

import { colors } from '../../utils/variables.styles';

import EditableSection from '../../components/EditableSection';
import SelectEdit from '../../components/SelectEdit';

import selector from './windowsEditor.selector';

import { infoShape } from '../../types/info.types';
import { availableType } from '../../types/available.types';

import { setSelected as setSelectedParameter } from '../Construction/construction.reducer';

const Container = styled.div`
  background: ${colors.mainLighter};
  padding: 10px 24px;
  margin-left: -27px;
`;

const Spacer = styled.div`
  padding: 10px 24px;
  font-weight: 600;
  max-width: 280px;
`;

const ImgWrapper = styled.img`
  max-width: 100%;
  display: block;
  margin: 15px auto;
`;

const ButtonWrapper = styled.div`
  padding-top: 5px;
`;

const ConstructionExtras = ({
  collapsed,
  loaded,
  window_back_count_available,
  window_back_count_info,
  window_back_count_selected,
  window_back_size_available,
  window_back_size_info,
  window_back_size_selected,
  // window_front_count_available,
  // window_front_count_info,
  // window_front_count_selected,
  // window_front_size_available,
  // window_front_size_info,
  // window_front_size_selected,
  window_left_count_available,
  window_left_count_info,
  window_left_count_selected,
  window_left_size_available,
  window_left_size_info,
  window_left_size_selected,
  window_right_count_available,
  window_right_count_info,
  window_right_count_selected,
  window_right_size_available,
  window_right_size_info,
  window_right_size_selected,
  setSelected
}) => {
  const openModal = () => {
    Modal.info({
      title: window_back_count_info && window_back_count_info.info_title,
      content: (
        <React.Fragment>
          {
            window_back_count_info ? (
              <React.Fragment>
                {
                  window_back_count_info.img_one ? (
                    <ImgWrapper src={window_back_count_info.img_one} />
                  ) : null
                }
                {
                  window_back_count_info.img_two ? (
                    <ImgWrapper src={window_back_count_info.img_two} />
                  ) : null
                }
                {window_back_count_info.info_content}
                {
                  window_back_count_info.img_three ? (
                    <ImgWrapper src={window_back_count_info.imgimg_three_one} />
                  ) : null
                }
                {
                  window_back_count_info.img_four ? (
                    <ImgWrapper src={window_back_count_info.img_four} />
                  ) : null
                }
              </React.Fragment>
            ) : null
          }
        </React.Fragment>
      )
    });
  };

  return (
    <React.Fragment>
      {
        loaded ? (
          <EditableSection
            collapsed={collapsed}
            title="Okna uchylne PCV"
            nested
            subsection={true}
          >
            <Spacer>
              Umiejscowienie okien proszę opisać w uwagach po klknięciu KUP TERAZ
            </Spacer>
            <SelectEdit
              selected={window_back_count_selected}
              loaded={loaded}
              title={window_back_count_info.name}
              onChange={(id) => { setSelected('window_back_count', id); }}
              options={window_back_count_available.map((el) => ({
                id: el.id,
                value: `${el.value}`
              }))}
              nested
            />
            <SelectEdit
              selected={window_back_size_selected}
              loaded={loaded}
              title={window_back_size_info.name}
              onChange={(id) => { setSelected('window_back_size', id); }}
              options={window_back_size_available.map((el) => ({
                id: el.id,
                value: `${el.value}`
              }))}
              nested
            />
            {/* <SelectEdit
              selected={window_front_count_selected}
              loaded={loaded}
              title={window_front_count_info.name}
              info={window_front_count_info}
              onChange={(id) => { setSelected('window_front_count', id); }}
              options={window_front_count_available.map((el) => ({
                id: el.id,
                value: `${el.value}`
              }))}
              nested
            /> */}
            {/* <SelectEdit
              selected={window_front_size_selected}
              loaded={loaded}
              title={window_front_size_info.name}
              info={window_front_size_info}
              onChange={(id) => { setSelected('window_front_size', id); }}
              options={window_front_size_available.map((el) => ({
                id: el.id,
                value: `${el.value}`
              }))}
              nested
            /> */}
            <SelectEdit
              selected={window_left_count_selected}
              loaded={loaded}
              title={window_left_count_info.name}
              onChange={(id) => { setSelected('window_left_count', id); }}
              options={window_left_count_available.map((el) => ({
                id: el.id,
                value: `${el.value}`
              }))}
              nested
            />
            <SelectEdit
              selected={window_left_size_selected}
              loaded={loaded}
              title={window_left_size_info.name}
              onChange={(id) => { setSelected('window_left_size', id); }}
              options={window_left_size_available.map((el) => ({
                id: el.id,
                value: `${el.value}`
              }))}
              nested
            />
            <SelectEdit
              selected={window_right_count_selected}
              loaded={loaded}
              title={window_right_count_info.name}
              onChange={(id) => { setSelected('window_right_count', id); }}
              options={window_right_count_available.map((el) => ({
                id: el.id,
                value: `${el.value}`
              }))}
              nested
            />
            <SelectEdit
              selected={window_right_size_selected}
              loaded={loaded}
              title={window_right_size_info.name}
              onChange={(id) => { setSelected('window_right_size', id); }}
              options={window_right_size_available.map((el) => ({
                id: el.id,
                value: `${el.value}`
              }))}
              nested
            />
            <Container>
              <ButtonWrapper>
                <Button
                  icon="info-circle"
                  type="primary"
                  block
                  onClick={openModal}
                >
                  Więcej informacji
                </Button>
              </ButtonWrapper>
            </Container>
          </EditableSection>
        ) : null
      }
    </React.Fragment>
  );
};

ConstructionExtras.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  loaded: PropTypes.bool,
  window_back_count_available: PropTypes.arrayOf(availableType),
  window_back_count_info: infoShape,
  window_back_count_selected: PropTypes.number,
  window_back_size_available: PropTypes.arrayOf(availableType),
  window_back_size_info: infoShape,
  window_back_size_selected: PropTypes.number,
  // window_front_count_available: PropTypes.arrayOf(availableType),
  // window_front_count_info: infoShape,
  // window_front_count_selected: PropTypes.number,
  // window_front_size_available: PropTypes.arrayOf(availableType),
  // window_front_size_info: infoShape,
  // window_front_size_selected: PropTypes.number,
  window_left_count_available: PropTypes.arrayOf(availableType),
  window_left_count_info: infoShape,
  window_left_count_selected: PropTypes.number,
  window_left_size_available: PropTypes.arrayOf(availableType),
  window_left_size_info: infoShape,
  window_left_size_selected: PropTypes.number,
  window_right_count_available: PropTypes.arrayOf(availableType),
  window_right_count_info: infoShape,
  window_right_count_selected: PropTypes.number,
  window_right_size_available: PropTypes.arrayOf(availableType),
  window_right_size_info: infoShape,
  window_right_size_selected: PropTypes.number
};

ConstructionExtras.defaultProps = {
  loaded: false,
  window_back_count_available: [],
  window_back_count_info: null,
  window_back_count_selected: null,
  window_back_size_available: [],
  window_back_size_info: null,
  window_back_size_selected: null,
  // window_front_count_available: [],
  // window_front_count_info: null,
  // window_front_count_selected: null,
  // window_front_size_available: [],
  // window_front_size_info: null,
  // window_front_size_selected: null,
  window_left_count_available: [],
  window_left_count_info: null,
  window_left_count_selected: null,
  window_left_size_available: [],
  window_left_size_info: null,
  window_left_size_selected: null,
  window_right_count_available: [],
  window_right_count_info: null,
  window_right_count_selected: null,
  window_right_size_available: [],
  window_right_size_info: null,
  window_right_size_selected: null
};

const mapDispatchToProps = {
  setSelected: setSelectedParameter
};

export default connect(selector, mapDispatchToProps)(ConstructionExtras);
