import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;
const drawerSelector = (state) => state.Drawer;

export default createSelector(
  drawerSelector,
  constructionSelector,
  (drawer, construction) => ({
    ...drawer,
    voivodeship: construction.available.voivodeship,
    parameters_loaded: construction.parameters_loaded,
    price: construction.price,
    transport: construction.transport
  })
);
