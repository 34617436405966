import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => {
    const currentWidth = construction.available.width.find(
      (el) => el.id === construction.selected.width
    );

    return ({
      info: construction.info.gate,
      loaded: construction.parameters_loaded,
      selected: construction.selected.gate,
      width: currentWidth && currentWidth.attributes.value_cm,
      subtypes: {
        vertical_gate: construction.subtypes.vertical_gate,
        horizontal_gate: construction.subtypes.horizontal_gate,
        double_horizontal_gate: construction.subtypes.double_horizontal_gate,
        double_vertical_gate: construction.subtypes.double_vertical_gate,
        mixed_gate: construction.subtypes.mixed_gate
      }
    });
  }
);
