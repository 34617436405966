import styled, { css } from 'styled-components';

import { colors, sider } from '../../utils/variables.styles';

const IconWrapper = styled.div`
  margin-right: 20px;
  font-size: 22px;

  ${(props) => props.collapsed && css`
    margin-right: 0;
  `};
  
  color: ${ colors.primaryFontColor };
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 45px;
  background: ${colors.main};
  padding: 12px 24px;
  margin-left: 0;
  transition: ${sider.transitionLength}s;

  ${(props) => props.pointer && css`
    cursor: pointer;
  `};

  ${(props) => props.collapsed && css`
    margin-left: 5px;
  `};

  ${(props) => props.nested && css`
    background: ${colors.mainLight}
    padding-right: 36px;
  `};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const OpenableWrapper = styled.div`
  transition: ${sider.transitionLength}s;

  ${(props) => props.opened && css`
    transform: rotate(180deg);
  `}
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid ${colors.arrowColor};

  ${(props) => props.subsection && css`
    border-top: 4px solid ${ colors.main };
  `};
`;

const CollapseWrapper = styled.div`
  opacity: 1;
  transition: ${sider.transitionLength}s;

  ${(props) => props.collapsed && css`
    opacity: 0;
    position: absolute;
    transition: none;
  `};
`;

const TextWrapper = styled.div`
  white-space: nowrap;
  color: ${ colors.primaryFontColor };

  ${(props) => props.subsection && css`
    color: ${ colors.main };
    font-weight: bolder;
    font-size: 16px;
  `};
`;

export {
  IconWrapper,
  Container,
  ContentWrapper,
  OpenableWrapper,
  ArrowDown,
  CollapseWrapper,
  TextWrapper
};
