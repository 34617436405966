import * as THREE from 'three';
import Color from 'color';
import { getStatics } from '../../../config';

import { loadTexture } from './loaders';

const getMaterial = async ({ hexa, texture }) => {
  if (texture) {
    const textureLoader = new THREE.TextureLoader();

    const loadedTexture = await loadTexture(textureLoader, texture);
    loadedTexture.wrapS = THREE.RepeatWrapping;
    loadedTexture.wrapT = THREE.RepeatWrapping;

    return new THREE.MeshBasicMaterial({
      map: loadedTexture,
      transparent: false,
      opacity: 1
    });
  }

  return new THREE.MeshBasicMaterial({ color: new THREE.Color(hexa) });
};

const getRoofLinesMaterial = async (isFirst, width, length) => {
  const textureLoader = new THREE.TextureLoader();
  const lineTexture = await loadTexture(textureLoader, getStatics('line.png'));

  lineTexture.wrapS = THREE.RepeatWrapping;
  lineTexture.wrapT = THREE.RepeatWrapping;

  if (isFirst) {
    lineTexture.rotation = 1.57079633;
    lineTexture.repeat.set(0.26 * width, 10);
  } else {
    lineTexture.repeat.set(0.39 * length, 10);
  }

  return new THREE.MeshBasicMaterial({
    map: lineTexture,
    transparent: true,
    opacity: 1
  });
};

const getTileMaterial = async (horizontal = false, width, length) => {
  const textureLoader = new THREE.TextureLoader();
  const tileTexture = await loadTexture(textureLoader, getStatics('tile.png'));

  tileTexture.wrapS = THREE.RepeatWrapping;
  tileTexture.wrapT = THREE.RepeatWrapping;

  if (horizontal) {
    tileTexture.rotation = 1.57079633;
    tileTexture.repeat.set(0.1 * width, 0.08 * length);
  } else {
    tileTexture.repeat.set(0.12 * length, 0.08 * width);
  }

  return new THREE.MeshBasicMaterial({
    map: tileTexture,
    transparent: true,
    opacity: 1
  });
};

const getFrontLinesMaterial = async (horizontal, width, height, isSecond = false) => {
  const textureLoader = new THREE.TextureLoader();
  const lineTexture = await loadTexture(textureLoader, getStatics('line.png'));
  lineTexture.wrapS = THREE.RepeatWrapping;
  lineTexture.wrapT = THREE.RepeatWrapping;

  if (horizontal) {
    lineTexture.rotation = 1.57079633;
    lineTexture.repeat.set(0.14 * height, 10);
  } else {
    const multiple = isSecond ? 0.39 : 0.26;
    lineTexture.repeat.set(multiple * width, 10);
  }

  return new THREE.MeshBasicMaterial({
    map: lineTexture,
    transparent: true,
    opacity: 1
  });
};

const styleModels = async (
  model,
  colors,
  pressing,
  activeConstruction,
  firstGate,
  secondGate,
  width,
  isTile,
  length,
  height
) => {
  const wallsColorMaterial = await getMaterial(colors.walls_color);
  const roofColorMaterial = await getMaterial(colors.roof_color);
  let addonsColor = null;

  if (colors.tooling_color) {
    addonsColor = new THREE.MeshBasicMaterial({
      color: new THREE.Color(Color(colors.tooling_color.hexa).darken(0.1).hex())
    });
  }
  const roofLightColorMaterial = new THREE.MeshBasicMaterial({
    color: new THREE.Color(Color(colors.roof_color.hexa).darken(0.1).hex())
  });

  const gateColorMaterial = await getMaterial(colors.gates_color);
  const roofLinesMaterial = isTile ? (
    await getTileMaterial(activeConstruction === 1, width, length)
  ) : (
    await getRoofLinesMaterial(activeConstruction === 1, width, length)
  );

  const wallsFrontLineMaterial = await getFrontLinesMaterial(
    pressing === 2,
    width,
    height,
    activeConstruction === 2
  );
  const wallsSideLineMaterial = await getFrontLinesMaterial(
    pressing === 2,
    length,
    height,
    activeConstruction === 2
  );

  const first = firstGate && (firstGate.value);
  const second = secondGate && (secondGate.value);

  const firstGateLineMaterial = await getFrontLinesMaterial(
    [2, 3].indexOf(pressing) > -1,
    first + 150,
    height
  );
  const secondGateLineMaterial = await getFrontLinesMaterial(
    [2, 3].indexOf(pressing) > -1,
    second + 150,
    height
  );

  if (model) {
    model.traverse((child) => {
      /* eslint-disable no-param-reassign */
      if (child.name.match(/^Solid/)) {
        child.material = wallsColorMaterial;
      } else if (child.name.match(/^Stripes_/)) {
        child.material = wallsFrontLineMaterial;
      } else if (child.name.match(/^StripesSide/)) {
        child.material = wallsSideLineMaterial;
      } else if (child.name.match(/^Roof_/)) {
        child.material = roofColorMaterial;
      } else if (child.name.match(/^Roof2/)) {
        child.material = roofLightColorMaterial;
      } else if (child.name.match(/^Pressing/)) {
        child.material = roofLinesMaterial;
      } else if (child.name.match(/^Gate_/)) {
        child.material = gateColorMaterial;
      } else if (child.name.match(/^SecondGate_/)) {
        child.material = gateColorMaterial;
      } else if (child.name.match(/^Gate/)) {
        child.material = firstGateLineMaterial;
      } else if (child.name.match(/^SecondGate/)) {
        child.material = secondGateLineMaterial;
      } else if (child.name.match(/Horizontal/) || child.name.match(/Vertical/)) {
        child.material = addonsColor || new THREE.MeshBasicMaterial({
          color: new THREE.Color(0x632125)
        });
      }
      /* eslint-enable no-param-reassign */
    });
  }
};

export default styleModels;
