import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => ({
    loaded: construction.parameters_loaded,
    window_back_count_available: [...construction.available.window_back_count],
    window_back_count_info: construction.info.window_back_count,
    window_back_count_selected: construction.selected.window_back_count,
    window_back_size_available: [...construction.available.window_back_size],
    window_back_size_info: construction.info.window_back_size,
    window_back_size_selected: construction.selected.window_back_size,
    window_front_count_available: [...construction.available.window_front_count],
    window_front_count_info: construction.info.window_front_count,
    window_front_count_selected: construction.selected.window_front_count,
    window_front_size_available: [...construction.available.window_front_size],
    window_front_size_info: construction.info.window_front_size,
    window_front_size_selected: construction.selected.window_front_size,
    window_left_count_available: [...construction.available.window_left_count],
    window_left_count_info: construction.info.window_left_count,
    window_left_count_selected: construction.selected.window_left_count,
    window_left_size_available: [...construction.available.window_left_size],
    window_left_size_info: construction.info.window_left_size,
    window_left_size_selected: construction.selected.window_left_size,
    window_right_count_available: [...construction.available.window_right_count],
    window_right_count_info: construction.info.window_right_count,
    window_right_count_selected: construction.selected.window_right_count,
    window_right_size_available: [...construction.available.window_right_size],
    window_right_size_info: construction.info.window_right_size,
    window_right_size_selected: construction.selected.window_right_size
  })
);
