import PropTypes from 'prop-types';

const attributesPropTypes = PropTypes.shape({
  img: PropTypes.string,
  is_default: PropTypes.bool,
  name: PropTypes.string,
  slope: PropTypes.string
});

const constructionPropTypes = PropTypes.shape({
  attributes: attributesPropTypes,
  id: PropTypes.number,
  type: PropTypes.string
});

export default constructionPropTypes;
