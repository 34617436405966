import { createSelector } from 'reselect';

const drawerSelecor = (state) => state.Drawer;
const constructionSelecor = (state) => state.Construction;

export default createSelector(
  drawerSelecor,
  constructionSelecor,
  (drawer, construction) => ({
    ...drawer,
    voivodeship: construction.available.voivodeship,
    voivodeship_selected: construction.selected.voivodeship,
    price: construction.price,
    transport: construction.transport
  })
);
