import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => ({
    available: [...construction.available.second_height],
    info: construction.info.second_height,
    loaded: construction.parameters_loaded,
    selected: construction.selected.second_height
  })
);
