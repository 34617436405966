const loadObject = (objLoader, url) => new Promise(
  (resolve, reject) => {
    objLoader.load(
      url,
      (object) => { resolve(object); },
      () => {},
      (error) => { reject(error); }
    );
  }
);

const loadTexture = (textureLoader, url) => new Promise(
  (resolve, reject) => {
    textureLoader.load(
      url,
      (texture) => { resolve(texture); },
      () => {},
      (error) => { reject(error); }
    );
  }
);

export {
  loadObject,
  loadTexture
};
