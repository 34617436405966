import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => ({
    loaded: construction.parameters_loaded,
    available: construction.available.pressing,
    info: construction.info.pressing,
    selected: construction.selected.pressing
  })
);
