import React from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import * as THREE from 'three';
import * as OBJLoader from 'three-obj-loader';

import { getStatics } from '../../config';
import { SceneWrapper } from './Scene.styles';

import { loadObject } from './utils/loaders';
import initScene from './utils/initScene';
import styleModels from './utils/styleModels';

OBJLoader(THREE);

class App extends React.Component {
  static propTypes = {
    big: PropTypes.bool.isRequired,
    activeConstruction: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    colors: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    firstGate: PropTypes.object,
    height: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    isTile: PropTypes.bool,
    length: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    pressing: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    secondGate: PropTypes.object,
    width: PropTypes.number
  }

  static defaultProps = {
    height: 0,
    width: 0,
    length: 0,
    activeConstruction: 1,
    colors: null,
    pressing: null,
    firstGate: null,
    secondGate: null,
    isTile: false
  }

  model = null;

  componentDidMount() {
    this.initScene();
    this.initObject().then(() => {
      this.scaleModel();
    });

    this.start();
  }

  shouldComponentUpdate = async (nextProps) => {
    if (nextProps.big !== this.props.big) {
      setTimeout(this.scaleScene, 0);
    }
    if (!_isEqual(nextProps, this.props)) {
      const {
        colors,
        pressing,
        secondGate,
        firstGate,
        width,
        isTile,
        length,
        height
      } = nextProps;

      await styleModels(
        this.model,
        colors,
        pressing,
        this.props.activeConstruction,
        firstGate,
        secondGate,
        width,
        isTile,
        length,
        height
      );
    }

    return !_isEqual(nextProps, this.props);
  }

  componentDidUpdate() {
    this.scaleModel();
  }

  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }

  scaleGate = () => {
    const { firstGate, secondGate } = this.props;

    this.model.traverse((child) => {
      if (child.name.match(/^Gate/) || child.name.match(/^Horizontal/) || child.name.match(/^Vertical/)) {
        if (child.name.match(/^Vertical/)) {
          // eslint-disable-next-line no-param-reassign
          child.visible = firstGate.isVertical;
        }
        if (child.name.match(/^Horizontal/)) {
          // eslint-disable-next-line no-param-reassign
          child.visible = !firstGate.isVertical;
        }

        const gateScale = firstGate.value / this.props.width;
        const space = (this.props.width - firstGate.value);
        const spaceScale = space / this.props.width;

        const firstGateSpace = (firstGate.space ? firstGate.space : 0);
        const additionalSpace = (firstGateSpace / this.props.width) * 2;

        child.scale.set(1, 1, gateScale);
        child.position.set(0, 0, -spaceScale + additionalSpace);
      }

      if (child.name.match(/^Second/) || child.name.match(/^SecondHorizontal/) || child.name.match(/^SecondVertical/)) {
        const isSecondGate = !!secondGate;
        // eslint-disable-next-line no-param-reassign
        child.visible = isSecondGate;
        if (isSecondGate) {
          if (child.name.match(/^SecondVertical/)) {
            // eslint-disable-next-line no-param-reassign
            child.visible = secondGate.isVertical;
          }
          if (child.name.match(/^SecondHorizontal/)) {
            // eslint-disable-next-line no-param-reassign
            child.visible = !secondGate.isVertical;
          }
          const gateScale = secondGate.value / this.props.width;
          const space = (this.props.width - secondGate.value);
          const spaceScale = space / this.props.width;

          const secondGateSpace = secondGate.space ? secondGate.space : 0;
          const additionalSpace = (secondGateSpace / this.props.width) * 2;

          child.scale.set(1, 1, gateScale);
          child.position.set(0, 0, spaceScale - additionalSpace);
        }
      }
    });
  }

  scaleModel = () => {
    if (this.model) {
      this.model.scale.set(
        this.props.length / 100,
        this.props.height / 100,
        this.props.width / 100
      );

      this.scaleGate();
    }
  }

  initScene = () => {
    const {
      renderer,
      camera,
      scene,
      controls,
      clock
    } = initScene(this.mount);
    this.renderer = renderer;
    this.camera = camera;
    this.scene = scene;
    this.controls = controls;
    this.clock = clock;
  }

  loadModels = async () => {
    this.THREE = THREE;
    const objLoader = new this.THREE.OBJLoader();

    const modelURL = this.props.activeConstruction !== 1 ? (
      getStatics('garage.obj')
    ) : (
      getStatics('garage2.obj')
    );
    this.model = await loadObject(objLoader, modelURL);

    this.scene.add(this.model);
  }

  scaleScene = () => {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }

  initObject = async () => {
    const {
      colors,
      pressing,
      isTile,
      activeConstruction,
      firstGate,
      secondGate,
      width,
      length,
      height
    } = this.props;

    await this.loadModels();
    await styleModels(
      this.model,
      colors,
      pressing,
      activeConstruction,
      firstGate,
      secondGate,
      width,
      isTile,
      length,
      height
    );
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId);
  }

  animate = () => {
    requestAnimationFrame(this.animate);
    this.renderScene();
  }

  renderScene = () => {
    // const delta = this.clock.getDelta();
    // this.controls.update(delta);
    this.renderer.render(this.scene, this.camera);
  }

  render() {
    const width = this.props.big ? 1058 : 738;
    const canvasWidth = width > window.innerWidth ? window.innerWidth - 35 : width;

    return (
      <SceneWrapper>
        <div
          style={{ maxWidth: '100%', width: `${canvasWidth}px`, height: window.innerWidth < 500 ? '200px' : 'calc(85vh - 122px)' }}
          ref={(mount) => { this.mount = mount; }}
        />
      </SceneWrapper>
    );
  }
}

export default App;
