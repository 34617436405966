import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => ({
    available: [...construction.available.length],
    info: construction.info.length,
    loaded: construction.parameters_loaded,
    selected: construction.selected.length
  })
);
