import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => ({
    available: [...construction.available.padlock_holder],
    info: construction.info.padlock_holder,
    loaded: construction.parameters_loaded,
    selected: construction.selected.padlock_holder
  })
);
