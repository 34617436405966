import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  margin: -20px;
  @media (max-width: 640px) {
    align-items: center;
    justify-content: center;
  }
`;

const StyledImg = styled.img`
  display: block;
  width: 80px;
  height: 80px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 46px;
  padding-bottom: 6.5px;

  @media (max-width: 640px) {
    font-size: 60px;
    width: 80px;
    height: 80px;
  }
`;

const BoxWrapper = styled.div`
  padding: 20px;
  max-width: 190px;
  @media (max-width: 640px) {
    padding: 5px;
  }
`;

const TextWrapper = styled.div`
  max-width: 100%;
  padding-top: 12px;
  text-align: center;
`;

const ConstructionWrapper = styled.div`
  padding: 17.2px 34px;

  @media (max-width: 640px) {
    padding: 9px;
  }

`;

const IIConWrapper = styled.div`
  padding: 24px 24px 17.2px 24px;

  @media (max-width: 640px) {
    padding: 9px;
  }
`;

export {
  IIConWrapper,
  Wrapper,
  StyledImg,
  BoxWrapper,
  TextWrapper,
  IconWrapper,
  ConstructionWrapper
};
