import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => ({
    loaded: construction.parameters_loaded,
	
	walls_color_info: construction.info.walls_color,
    walls_color_selected: construction.selected.walls_color,

    gates_color_info: construction.info.gates_color,
    gates_color_selected: construction.selected.gates_color,

    roof_color_info: construction.info.roof_color,
    roof_color_selected: construction.selected.roof_color,

    tooling_color_info: construction.info.tooling_color,
    tooling_color_selected: construction.selected.tooling_color,

    doors_front_color_info: construction.info.doors_front_color,
    doors_front_color_selected: construction.selected.doors_front_color,

    doors_back_color_info: construction.info.doors_back_color,
    doors_back_color_selected: construction.selected.doors_back_color,

    doors_left_color_info: construction.info.doors_left_color,
    doors_left_color_selected: construction.selected.doors_left_color,

    doors_right_color_info: construction.info.doors_right_color,
    doors_right_color_selected: construction.selected.doors_right_color,

    available: {
      matt: construction.available.matt,
      wooden: construction.available.wooden,
      smooth: construction.available.smooth
    },

    colors: construction.available.colors
  })
);
