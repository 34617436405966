import styled, { css } from 'styled-components';

import { colors } from '../../utils/variables.styles';

const StyledImage = styled.img`
  display: block;
  cursor: pointer;
  max-width: 100%;

  ${(props) => props.selected && css`
    box-shadow: 0px 0px 0px 3px ${colors.main};
    cursor: auto;
  `}
`;

const ImagesWrapper = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  margin: -20px;
`;

const ImageWrapper = styled.div`
  padding: 20px;
  max-width: 100%;
`;

const TabWrapper = styled.div`
  margin: 15px;
  max-width: 100%;
`;

export {
  StyledImage,
  ImagesWrapper,
  ImageWrapper,
  TabWrapper
};
