import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConstructionHeader from '../../components/ConstructionHeader';

import { handleConstructionChange } from '../Construction/construction.reducer';
import selector from './header.selector';


class ConstructionHeaderContainer extends React.Component {
  static propTypes = {
    activeConstruction: PropTypes.number
  };

  static defaultProps = {
    activeConstruction: null
  };

  onConstructionChange = (id) => {
    this.props.handleConstructionChange(id);
  }

  render() {
    return (
      <ConstructionHeader
        {...this.props}
        onConstructionChange={this.onConstructionChange}
      />
    );
  }
}

const mapDispatchToProps = {
  handleConstructionChange
};

export default connect(selector, mapDispatchToProps)(ConstructionHeaderContainer);
