import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import { Tabs } from 'antd';

import {
  StyledImage,
  ImagesWrapper,
  ImageWrapper,
  TabWrapper
} from './GatesSubtypes.styles';

const getTabName = (key) => {
  switch (key) {
    case 'vertical_gate':
      return 'Uchylna';
    case 'horizontal_gate':
      return 'Dwuskrzydłowa';
    case 'double_horizontal_gate':
      return '2x Dwuskrzydłowe';
    case 'double_vertical_gate':
      return '2x Uchylne';
    case 'mixed_gate':
      return 'Dwuskrzydłowa i uchylna';
    default: return null;
  }
};

const GatesSubtypes = ({
  subtypes,
  selectedSubtype,
  selectedId,
  onItemSelected,
  setActiveSelectedSubtype
}) => (
  <Tabs
    activeKey={selectedSubtype}
    onChange={setActiveSelectedSubtype}
    type="card "
  >
    {
      _map(subtypes, (subtype, key) => (
        !_isEmpty(subtype) ? (
          <Tabs.TabPane tab={getTabName(key)} key={key}>
            <TabWrapper>
              <ImagesWrapper>
                {
                  _map(subtype, (el) => (
                    <ImageWrapper key={`key__${el.id}`}>
                      <StyledImage
                        src={el.img}
                        selected={selectedId === el.id}
                        onClick={selectedId === el.id ? () => {} : () => { onItemSelected(el.id); }}
                      />
                    </ImageWrapper>
                  ))
                }
              </ImagesWrapper>
            </TabWrapper>
          </Tabs.TabPane>
        ) : null
      ))
    }
  </Tabs>
);

GatesSubtypes.propTypes = {
  setActiveSelectedSubtype: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  selectedId: PropTypes.number,
  selectedSubtype: PropTypes.string,
  subtypes: PropTypes.object // eslint-disable-line react/forbid-prop-types
};

GatesSubtypes.defaultProps = {
  subtypes: null,
  selectedSubtype: null,
  selectedId: null
};

export default GatesSubtypes;
