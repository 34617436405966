import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, Divider } from 'antd';
import SelectEdit from '../../components/SelectEdit';

import selector from './pressingEditor.selector';
import { setSelected } from '../Construction/construction.reducer';

import { availableType } from '../../types/available.types';
import { infoShape } from '../../types/info.types';

import {
  ModalContainer,
  StyledImg,
  ImgWrapper
} from './pressingEditor.styles';

class PressingEditor extends React.Component {
  static propTypes = {
    loaded: PropTypes.bool.isRequired,
    setSelected: PropTypes.func.isRequired,
    available: PropTypes.arrayOf(availableType),
    info: infoShape,
    selected: PropTypes.number
  }

  static defaultProps = {
    available: [],
    selected: null,
    info: null
  }

  state = {
    modalVisible: false
  }

  handleInsideChange = (value) => {
    if (value !== this.props.selected) {
      this.props.setSelected('pressing', value);
      this.closeModal();
    }
  }

  openModal = () => {
    this.setState({ modalVisible: true });
  }

  closeModal = () => {
    this.setState({ modalVisible: false });
  }

  render() {
    return (
      this.props.loaded ? (
        <React.Fragment>
          <SelectEdit
            selected={this.props.selected}
            loaded={this.props.loaded}
            onEditClick={this.openModal}
            title={this.props.info.name}
            options={this.props.available.map((el) => ({
              id: el.id,
              value: `${el.attributes.value}`
            }))}
          />
          <Modal
            title={this.props.info.name}
            visible={this.state.modalVisible}
            footer={null}
            onCancel={this.closeModal}
          >
            <ModalContainer>
              {
                this.props.available.map((el) => (
                  <div key={`inside__${el.attributes.value}`}>
                    <ImgWrapper
                      selected={el.id === this.props.selected}
                      onClick={() => this.handleInsideChange(el.id)}
                    >
                      <StyledImg src={el.attributes.img} />
                    </ImgWrapper>
                    <p>{el.attributes.value}</p>
                  </div>
                ))
              }
            </ModalContainer>
            <Divider>{this.props.info.info_title}</Divider>
            {this.props.info.info_content}
          </Modal>
        </React.Fragment>
      ) : <SelectEdit loading />
    );
  }
}

const mapDispatchToProps = {
  setSelected
};

export default connect(selector, mapDispatchToProps)(PressingEditor);
