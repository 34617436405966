const actionTypes = {
  OPEN: 'SELECT_EDIT/OPEN'
};

const initialState = {
  opened: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.OPEN: {
      return {
        ...state,
        opened: action.payload
      };
    }

    default:
      return state;
  }
}

export const open = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.OPEN,
    payload: id
  });
};

export const close = () => (dispatch) => {
  dispatch({
    type: actionTypes.OPEN,
    payload: null
  });
};
