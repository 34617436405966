import axios from 'axios';

import { BASE_URL } from '../config';

axios.defaults.baseURL = `${BASE_URL}api/`;

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get.Accept = 'application/json';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';
