/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Spin } from 'antd';
import StyledBox from './Box.styles';

const Box = ({
  children,
  center,
  pointer,
  onClick,
  active,
  loading,
  clear,
  loaderHeight,
  noTop,
  styles,
  wide,
  id
}) => (
  <StyledBox
    id={id}
    active={active}
    center={center || loading}
    pointer={pointer}
    onClick={onClick}
    loading={loading}
    clear={clear}
    loaderHeight={loading && loaderHeight}
    noTop={noTop}
    wide={wide}
    styles={styles}
  >
    {
      loading ? (
        <Spin />
      ) : children
    }
  </StyledBox>
);

Box.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  center: PropTypes.bool,
  clear: PropTypes.bool,
  id: PropTypes.string,
  loaderHeight: PropTypes.number,
  loading: PropTypes.bool,
  noTop: PropTypes.bool,
  pointer: PropTypes.bool,
  styles: PropTypes.object,
  wide: PropTypes.bool,
  onClick: PropTypes.func
};

Box.defaultProps = {
  active: false,
  center: false,
  clear: false,
  noTop: false,
  id: null,
  loaderHeight: null,
  loading: false,
  pointer: false,
  wide: false,
  onClick: () => {},
  styles: null
};

export default Box;
