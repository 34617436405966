import React from 'react';
import PropTypes from 'prop-types';

import SelectEdit from '../../components/SelectEdit';

import { availableType } from '../../types/available.types';
import { infoShape } from '../../types/info.types';

const GateEditor = ({
  loaded,
  available,
  height_available,
  height_info,
  height_selected,
  info,
  ramp_height_available,
  ramp_height_info,
  ramp_height_selected,
  ramp_width_available,
  ramp_width_info,
  ramp_width_selected,
  selected,
  width_available,
  width_info,
  width_selected
  // openModal
}) => (
  loaded ? (
    <React.Fragment>
      <SelectEdit
        selected={selected}
        loaded={loaded}
        title={info.name}
        info={info}
        options={available.map((el) => ({
          id: el.id,
          value: `${el.attributes.value}`
        }))}
      />
      <SelectEdit
        selected={height_selected}
        loaded={loaded}
        title={height_info.name}
        info={height_info}
        options={height_available.map((el) => ({
          id: el.id,
          value: `${el.attributes.value}cm`
        }))}
      />
      <SelectEdit
        selected={width_selected}
        loaded={loaded}
        title={width_info.name}
        info={width_info}
        options={width_available.map((el) => ({
          id: el.id,
          value: `${el.attributes.value}cm`
        }))}
      />
      <SelectEdit
        selected={ramp_width_selected}
        loaded={loaded}
        title={ramp_width_info.name}
        info={ramp_width_info}
        options={ramp_width_available.map((el) => ({
          id: el.id,
          value: `${el.attributes.value}cm`
        }))}
      />
      <SelectEdit
        selected={ramp_height_selected}
        loaded={loaded}
        title={ramp_height_info.name}
        info={ramp_height_info}
        options={ramp_height_available.map((el) => ({
          id: el.id,
          value: `${el.attributes.value}cm`
        }))}
      />
    </React.Fragment>
  ) : <SelectEdit loading />
);

GateEditor.propTypes = {
  loaded: PropTypes.bool.isRequired,
  available: PropTypes.arrayOf(availableType),
  height_available: PropTypes.arrayOf(availableType),
  height_info: infoShape,
  height_selected: PropTypes.number,
  info: infoShape,
  openModal: PropTypes.func,
  ramp_height_available: PropTypes.arrayOf(availableType),
  ramp_height_info: infoShape,
  ramp_height_selected: PropTypes.number,
  ramp_width_available: PropTypes.arrayOf(availableType),
  ramp_width_info: infoShape,
  ramp_width_selected: PropTypes.number,
  selected: PropTypes.number,
  width_available: PropTypes.arrayOf(availableType),
  width_info: infoShape,
  width_selected: PropTypes.number
};

GateEditor.defaultProps = {
  available: [],
  height_available: [],
  height_info: null,
  height_selected: null,
  info: null,
  openModal: () => {},
  ramp_height_available: [],
  ramp_height_info: null,
  ramp_height_selected: null,
  ramp_width_available: [],
  ramp_width_info: null,
  ramp_width_selected: null,
  selected: null,
  width_available: [],
  width_info: null,
  width_selected: null
};

export default GateEditor;
