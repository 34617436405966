import { createSelector } from 'reselect';
import _forEach from 'lodash/forEach';

import { colors } from '../Construction/utils/convertCalculationResponse';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => {
    const currentWidth = construction.available.width.find(
      (el) => el.id === construction.selected.width
    );
    const currentLength = construction.available.length.find(
      (el) => el.id === construction.selected.length
    );
    const currentHeight = construction.available.first_height.find(
      (el) => el.id === construction.selected.first_height
    );
    const pressing = construction.available.pressing.find(
      (el) => el.id === construction.selected.pressing
    );

    const firstGate = construction.available.gate_left_width.find(
      (el) => el.id === construction.selected.gate_left_width
    );

    const secondGate = construction.available.gate_right_width
    && construction.available.gate_right_width.find(
      (el) => el.id === construction.selected.gate_right_width
    );

    const gate_left_space = construction.available.gate_left_space
      && construction.available.gate_left_space[0].attributes.value;

    const gate_right_space = construction.available.gate_right_space
      && construction.available.gate_right_space[0].attributes.value;

    const gate_left = construction.available.gate_left
    && construction.available.gate_left.find((el) => el.id === construction.selected.gate_left);

    const gate_right = construction.available.gate_right
    && construction.available.gate_right.find((el) => el.id === construction.selected.gate_right);

    if (gate_left_space) {
      firstGate.attributes.space = gate_left_space;
    }

    if (gate_left) {
      firstGate.attributes.isVertical = gate_left.attributes.gate === 'vertical_gate';
    }

    if (gate_right && secondGate) {
      secondGate.attributes.isVertical = gate_right.attributes.gate === 'vertical_gate';
    }

    if (gate_right_space && secondGate) {
      secondGate.attributes.space = gate_right_space;
    }

    const isTile = construction.selected.inside === 2;

    const colorsData = {};
    _forEach(colors, (el) => {
      if (construction.selected[el]) {
        const selected = construction.available.colors.find(
          (el1) => (el1.id) === construction.selected[el]
        );

        if (selected) {
          colorsData[el] = selected;
        }
      }
    });

    return {
      width: currentWidth && currentWidth.attributes.value_cm,
      length: currentLength && currentLength.attributes.value_cm,
      height: currentHeight && currentHeight.attributes.value_cm,
      activeConstruction: construction.activeConstruction,
      constructionsLoaded: construction.constructionsLoaded,
      pressing: pressing && pressing.id,
      colors: colorsData,
      firstGate: firstGate && firstGate.attributes,
      secondGate: secondGate && secondGate.attributes,
      isTile
    };
  }
);
