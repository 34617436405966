import styled, { css } from 'styled-components';

import { colors } from '../../utils/variables.styles';

const Content = styled.div`
  display: flex;
  align-items: center;
  min-height: 45px;
  width: calc(100% - 65px);
`;

const StyledTitle = styled.div`
  margin-right: 5px;
  max-width: 190px;
  width: 100%;
`;

const SelectedWrapper = styled.div`
  width: 100%;
  font-weight: 600;
`;

const Container = styled.div`
  padding: 0 24px;
  background: ${colors.mainLight};

  ${(props) => props.nested && css`
    background: ${colors.mainLighter};
  `}
`;

export {
  Content,
  StyledTitle,
  SelectedWrapper,
  Container
};
