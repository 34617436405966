import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'antd';
import EditableSection from '../../components/EditableSection';

import WidthEditor from '../WidthEditor';
import LengthEditor from '../LengthEditor';
import UpliftEditor from '../UpliftEditor';
import FirstHeightOption from '../FirstHeightOption';
import SecondHeightOption from '../SecondHeightOption';


const DimensionsEditor = ({ collapsed }) => (
  <EditableSection
    collapsed={collapsed}
    icon={<Icon type="arrows-alt" />}
    title="Wymiary garażu"
  >
    <WidthEditor />
    <LengthEditor />
    <UpliftEditor />
    <FirstHeightOption />
    <SecondHeightOption />
	
  </EditableSection>
   
);


DimensionsEditor.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default DimensionsEditor;
