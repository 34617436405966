import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';

import {
  Drawer as AntDrawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Spin,
  message,
  Checkbox
} from 'antd';

import selector from './Drawer.selector';
import { close, handleSubmit as sendForm } from './Drawer.reducer';
import { colors } from '../../../utils/variables.styles';

const { Option } = Select;

const TabRow = styled.div`
  display: flex;
`;

const Tab = styled.div`
  border-style: solid;
  border-color: black;
  border-width: 0;
  width: 115px;
  font-size: 14px;

  ${(props) => props.bottom && css`
    border-bottom-width: 1px;
  `};

  ${(props) => props.right && css`
    width: 200px;
  `};

  ${(props) => !props.right && css`
    text-align: right;
  `};

  ${(props) => props.bold && css`
    font-weight: 600;
  `};
`;

const TableContent = styled.div`
  padding-bottom: 130px;

  @media (max-width: 1000px) {
    padding-bottom: 200px;
  }
`;

const Drawer = ({
  onClose,
  isOpen,
  buyMode,
  form,
  voivodeship,
  submit,
  price,
  transport,
  voivodeship_selected
}) => {
  const [errorPolicy, setPolicyError] = useState(false);
  const [policy, setPolicy] = useState(false);
  const { getFieldDecorator, resetFields } = form;
  const [spinning, setSpinning] = useState(false);
  const closeDrawer = () => {
    setPolicyError(false);
    setPolicy(false);
    resetFields();
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, fieldsValue) => {
      if (!fieldsValue.policy) {
        setPolicyError(true);
      }

      if (err || !fieldsValue.policy) {
        // eslint-disable-next-line no-useless-return
        return;
      }

      setSpinning(true);

      submit(fieldsValue).then(({ data }) => {
        setSpinning(false);
        message.success(data.message, 5);
        closeDrawer();
      });
    });
  };

  const check = (e) => {
    setPolicy(e.target.checked);

    form.validateFields(['policy'], { force: true });
  };

  return (
    <AntDrawer
      title={buyMode ? 'Formularz zamówienia' : 'Formularz kontaktowy'}
      width={720}
      onClose={closeDrawer}
      visible={isOpen}
    >
      <Form layout="vertical" onSubmit={handleSubmit} hideRequiredMark>
        <Spin spinning={spinning}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Imię">
                {getFieldDecorator('first_name', {
                  rules: [{ required: true, message: 'Proszę wpisać imię' }]
                })(<Input placeholder="Proszę wpisać imię" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              {
                buyMode
                  ? (
                    <Form.Item label="Nazwisko">
                      {getFieldDecorator('last_name', {
                        rules: [{ required: true, message: 'Proszę wpisać nazwisko' }]
                      })(<Input placeholder="Proszę wpisać nazwisko" />)}
                    </Form.Item>
                  ) : null
              }
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Adres">
                {getFieldDecorator('address', {
                  rules: [{ required: true, message: 'Proszę wpisać adres' }]
                })(<Input placeholder="Proszę wpisać adres" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              {
                !buyMode && voivodeship.length > 0 ? (
                  <Form.Item label="Województwo">
                    {getFieldDecorator('voivodeship', {
                      rules: [{ required: true, message: 'Proszę wybrać województwo' }]
                    })(
                      <Select placeholder="Proszę wybrać województwo">
                        {
                          voivodeship.map((el) => (
                            <Option
                              key={el.id}
                              value={el.attributes.name}
                            >
                              {el.attributes.name}
                            </Option>
                          ))
                        }
                      </Select>,
                    )}
                  </Form.Item>
                ) : null
              }
              {
                buyMode ? (
                  <Form.Item label="Kod pocztowy">
                    {
                      getFieldDecorator('postal_code', {
                        rules: [{ required: true, message: 'Proszę wpisać kod pocztowy' }]
                      })(<Input placeholder="Proszę wpisać kod pocztowy" />)
                    }
                  </Form.Item>
                ) : null
              }
            </Col>
          </Row>
          {
            buyMode ? (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Miejscowość">
                    {getFieldDecorator('city', {
                      rules: [
                        {
                          required: true,
                          message: 'Proszę wpisać miejscowość'
                        }
                      ]
                    })(<Input placeholder="Proszę wpisać miejscowość" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Telefon">
                    {getFieldDecorator('phone', {
                      rules: [
                        {
                          required: true,
                          message: 'Proszę wpisać numer telefonu'
                        }
                      ]
                    })(<Input placeholder="Proszę wpisać numer telefonu" />)}
                  </Form.Item>
                </Col>
              </Row>
            ) : null
          }
          {
            buyMode ? (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Email">
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          required: true,
                          message: 'Proszę wpisać adres email'
                        }
                      ]
                    })(<Input placeholder="Proszę wpisać adres email" />)}
                  </Form.Item>
                </Col>
              </Row>
            ) : null
          }
          {
            !buyMode ? (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Telefon">
                    {getFieldDecorator('phone', {
                      rules: [
                        {
                          required: true,
                          message: 'Proszę wpisać numer telefonu'
                        }
                      ]
                    })(<Input placeholder="Proszę wpisać numer telefonu" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Email">
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          required: true,
                          message: 'Proszę wpisać adres email'
                        }
                      ]
                    })(<Input placeholder="Proszę wpisać adres email" />)}
                  </Form.Item>
                </Col>
              </Row>
            ) : null
          }
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  buyMode
                    ? ('Uwagi')
                    : ('Opisz garaż (podaj wymiary, kolory dodatki - im więcej informacji nam podasz tym szybciej uzyskasz wycenę)')
                }
                style={{
                  margin: !buyMode ? '0 0 200px' : 0
                }}

              >
                {getFieldDecorator('descriptions', {
                  rules: [
                    {
                      message: 'Proszę wpisać uwagi',
                      required: !buyMode
                    }
                  ]
                })(<Input.TextArea rows={4} placeholder="Proszę wpisać uwagi" />)}
              </Form.Item>
            </Col>
          </Row>
        </Spin>
        {
          buyMode ? (
            <TableContent>
              <TabRow>
                <Tab bottom right>Cena garażu:</Tab>
                <Tab bottom>{price}zł</Tab>
              </TabRow>
              <TabRow>
                <Tab bottom right>
                  Transport ({ voivodeship_selected
                    && voivodeship.find((el) => el.id === voivodeship_selected).attributes.name
                  }):
                </Tab>
                <Tab bottom>{transport}zł</Tab>
              </TabRow>
              <TabRow>
                <Tab right bold>Suma:</Tab>
                <Tab bold>{transport + price}zł</Tab>
              </TabRow>
            </TableContent>
          ) : null
        }
        <div
          style={{
            position: 'absolute',
            left: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            bottom: 52,
            width: '100%',
            padding: '10px 16px',
            background: colors.drawerBackground,
            textAlign: 'right'
          }}
        >
          <Form.Item>
            {getFieldDecorator('policy', {
              initialValue: policy,
              valuePropName: 'checked',
              rules: [
                {
                  required: true
                }
              ]
            })(
              <Checkbox onChange={check}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                Wyrażam zgodę na przetważanie moich danych osobowych oraz akceptuję <b><a href="https://www.garaze24.com.pl/polityka-prywatnosci/" target="_blank">politykę prywatności</a></b>
              </Checkbox>
            )}
            {
              errorPolicy && !policy ? (
                <div style={{ color: colors.inValidFormColor }} className="ant-form-explain">
                  Należy zaakceptować
                </div>
              ) : null
            }
          </Form.Item>
        </div>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: 88,
            width: '100%',
            borderTop: `1px solid ${colors.secondaryBorderColor}`,
            padding: '10px 16px',
            background: colors.drawerBackground,
            textAlign: 'right'
          }}
        >

          <Button onClick={closeDrawer} style={{ marginRight: 8, color: colors.main }}>
            Anuluj
          </Button>
          <Button type="primary" htmlType="submit" loading={spinning}>
            { buyMode ? 'Zamów' : 'Wyślij' }
          </Button>
        </div>
      </Form>
    </AntDrawer>
  );
};

Drawer.propTypes = {
  buyMode: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  price: PropTypes.number,
  transport: PropTypes.number,
  voivodeship: PropTypes.arrayOf(PropTypes.object),
  voivodeship_selected: PropTypes.number
};

Drawer.defaultProps = {
  voivodeship: [],
  price: null,
  transport: null,
  voivodeship_selected: null
};

const mapDispatchToProps = {
  onClose: close,
  submit: sendForm
};

export default connect(selector, mapDispatchToProps)(Form.create()(Drawer));
