import styled, { css } from 'styled-components';

import { colors } from '../../utils/variables.styles';

const ModalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const StyledImg = styled.img`
  display: block;
  width: 100px;
  height: 100px;
`;

const ImgWrapper = styled.div`
  width: 150px;
  height: 150px;
  padding: 24px;
  border: 1px solid ${colors.borderColor};
  cursor: pointer;

  ${(props) => props.selected && css`
    cursor: auto;
    border: 1px solid ${colors.selectedMain};
  `};
`;

export {
  ModalContainer,
  StyledImg,
  ImgWrapper
};
