import styled, { css } from 'styled-components';
import { colors } from '../../utils/variables.styles';

const ModalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (max-width: 1000px) {
    justify-content: initial;
  }
`;

const StyledImg = styled.img`
  display: block;
  width: 100px;
  height: 100px;
`;

const ColorContainer = styled.div`
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 7px;
  background: ${colors.colorContainerBcg};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
`;

const Color = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 7px;
`;

const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  display: flex;

  ${(props) => props.click && css`
    cursor: pointer;
  `};
`;

const ColorName = styled.div`
  ${(props) => props.selected && css`
    font-weight: bold;
  `};
`;

export {
  ModalContainer,
  StyledImg,
  ColorContainer,
  Color,
  ColorWrapper,
  ItemContainer,
  ColorName
};
