import styled from 'styled-components';

import { colors } from '../../utils/variables.styles';

const Nested = styled.div`
  padding-left: 18px;
  background: ${colors.mainLight};
`;

export { Nested };
