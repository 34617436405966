import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonGroup from 'antd/lib/button/button-group';
import { Button, Modal } from 'antd';

import { Container } from './EditWrapper.styles';
import { infoShape } from '../../types/info.types';

const ImgWrapper = styled.img`
  max-width: 100%;
  display: block;
  margin: 15px auto;
`;

class EditWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onEditClick: PropTypes.func.isRequired,
    info: infoShape,
    nested: PropTypes.bool,
    noEdit: PropTypes.bool
  }

  static defaultProps = {
    info: null,
    nested: false,
    noEdit: false
  }

  openModal = () => {
    const { info } = this.props;

    Modal.info({
      title: info.info_title,
      content: (
        <React.Fragment>
          {
            info ? (
              <React.Fragment>
                {
                  info.img_one ? (
                    <ImgWrapper src={info.img_one} />
                  ) : null
                }
                {
                  info.img_two ? (
                    <ImgWrapper src={info.img_two} />
                  ) : null
                }
                {info.info_content}
                {
                  info.img_three ? (
                    <ImgWrapper src={info.imgimg_three_one} />
                  ) : null
                }
                {
                  info.img_four ? (
                    <ImgWrapper src={info.img_four} />
                  ) : null
                }
              </React.Fragment>
            ) : null
          }
        </React.Fragment>
      )
    });
  }

  render() {
    return (
      <Container nested={this.props.nested}>
        {this.props.children}
        <ButtonGroup>
          {
            !this.props.noEdit ? (
              <Button type="primary" icon="edit" onClick={this.props.onEditClick} />
            ) : null
          }
          {
            this.props.info ? (
              <Button type="primary" icon="info-circle" onClick={this.openModal} />
            ) : null
          }
        </ButtonGroup>
      </Container>
    );
  }
}

export default EditWrapper;
