import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => ({
    loaded: construction.parameters_loaded,
    available: [...construction.available.inside],
    info: construction.info.inside,
    selected: construction.selected.inside
  })
);
