import axios from 'axios';

import { getRequestData } from '../../../containers/Construction/construction.reducer';

const actionTypes = {
  OPEN: 'DRAWER/OPEN',
  CLOSE: 'DRAWER/CLOSE'
};

const initialState = {
  isOpen: false,
  buyMode: true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.OPEN: {
      return {
        ...state,
        isOpen: true,
        buyMode: action.payload
      };
    }

    case actionTypes.CLOSE: {
      return {
        ...state,
        isOpen: false
      };
    }

    default:
      return state;
  }
}

export const open = (buyMode) => (dispatch) => {
  dispatch({
    type: actionTypes.OPEN,
    payload: buyMode
  });
};

export const close = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLOSE
  });
};

export const handleSubmit = (values) => (dispatch, getStore) => {
  const { buyMode } = getStore().Drawer;

  if (buyMode) {
    const requestData = dispatch(getRequestData(true));

    if (!values.descriptions) {
      // eslint-disable-next-line no-param-reassign
      values.descriptions = 'Brak';
    }

    requestData.customer = {
      ...values
    };

    return axios.post('buy', requestData);
  }

  return axios.post('custom', values);
};
