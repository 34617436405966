import axios from 'axios';
import _cloneDeep from 'lodash/cloneDeep';

import convertCalculationResponse, { colors } from './utils/convertCalculationResponse';

const actionTypes = {
  setActiveConstruction: 'CONSTRUCTION/SET_ACTIVE_CONSTRUCTION',
  setConstructions: 'CONSTRUCTION/SET_CONSTRUCTIONS',
  setParameters: 'CONSTRUCTION/SET_PARAMETERS',
  setSelected: 'CONSTRUCTION/SET_SELECTED'
};

const initialState = {
  price: null,
  transport: null,
  activeConstruction: null,
  constructionsLoaded: false,
  constructions: [],
  parameters_loaded: false,
  selectedWidth: null,
  selectedLength: null,
  selectedUplift: null,
  parameters: {
    first_height: [],
    horizontal_gate: [],
    length: [],
    second_height: [],
    uplift: [],
    vertical_gate: [],
    voivodeship: [],
    width: [],
    gate: [],
    gate_count: [],
    gate_left: [],
    gate_left_height: [],
    gate_left_width: [],
    gate_ramp_left_height: [],
    gate_ramp_left_width: [],
    gate_right: [],
    gate_right_height: [],
    gate_right_width: [],
    gate_ramp_right_height: [],
    gate_ramp_right_width: []
  },
  selected: {
    first_height: null,
    horizontal_gate: null,
    length: null,
    second_height: null,
    uplift: null,
    vertical_gate: null,
    voivodeship: null,
    width: null,
    gate: null,
    gate_count: null,
    gate_left: null,
    gate_left_height: null,
    gate_left_width: null,
    gate_ramp_left_height: null,
    gate_ramp_left_width: null,
    gate_right: null,
    gate_right_height: null,
    gate_right_width: null,
    gate_ramp_right_height: null,
    gate_ramp_right_width: null
  },
  available: {
    first_height: [],
    horizontal_gate: [],
    length: [],
    second_height: [],
    uplift: [],
    vertical_gate: [],
    voivodeship: [],
    width: [],
    gate: [],
    gate_count: [],
    gate_left: [],
    gate_left_height: [],
    gate_left_width: [],
    gate_ramp_left_height: [],
    gate_ramp_left_width: [],
    gate_right: [],
    gate_right_height: [],
    gate_right_width: [],
    gate_ramp_right_height: [],
    gate_ramp_right_width: [],
    door_back_count: [],
    door_back_size: [],
    door_front_count: [],
    door_front_size: [],
    door_left_count: [],
    door_left_size: [],
    door_right_count: [],
    door_right_size: [],
    hole_back_count: [],
    hole_back_size: [],
    hole_front_count: [],
    hole_front_size: [],
    hole_left_count: [],
    hole_left_size: [],
    hole_right_count: [],
    hole_right_size: [],
    window_back_count: [],
    window_back_size: [],
    window_front_count: [],
    window_front_size: [],
    window_left_count: [],
    window_left_size: [],
    window_right_count: [],
    window_right_size: [],
    inside: [],
    structure: [],
    tooling: [],
    pressing: [],
    anchoring: [],
    bolt: [],
    closing: [],
    moisture_protection: [],
    padlock_holder: [],
    colors: []
  },
  info: {
    first_height: null,
    horizontal_gate: null,
    length: null,
    second_height: null,
    uplift: null,
    vertical_gate: null,
    voivodeship: null,
    width: null,
    gate: null,
    gate_count: null,
    gate_left: null,
    gate_left_height: null,
    gate_left_width: null,
    gate_ramp_left_height: null,
    gate_ramp_left_width: null,
    gate_right: null,
    gate_right_height: null,
    gate_right_width: null,
    gate_ramp_right_height: null,
    gate_ramp_right_width: null
  },
  subtypes: {
    door_back_count: null,
    door_back_size: null,
    door_front_count: null,
    door_front_size: null,
    door_left_count: null,
    door_left_size: null,
    door_right_count: null,
    door_right_size: null,
    hole_back_count: null,
    hole_back_size: null,
    hole_front_count: null,
    hole_front_size: null,
    hole_left_count: null,
    hole_left_size: null,
    hole_right_count: null,
    hole_right_size: null,
    horizontal_gate: null,
    vertical_gate: null,
    window_back_count: null,
    window_back_size: null,
    window_front_count: null,
    window_front_size: null,
    window_left_count: null,
    window_left_size: null,
    window_right_count: null,
    window_right_size: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.setActiveConstruction: {
      return {
        ...state,
        activeConstruction: action.payload,
        constructionsLoaded: false
      };
    }

    case actionTypes.setConstructions: {
      return {
        ...state,
        constructions: action.payload
      };
    }

    case actionTypes.setParameters: {
      const {
        available,
        parameters,
        info,
        selected,
        subtypes
      } = action.payload.parameters;

      return {
        ...state,
        price: action.payload.price,
        transport: action.payload.transport,
        available: _cloneDeep(available),
        parameters: _cloneDeep(parameters),
        info: _cloneDeep(info),
        selected: _cloneDeep(selected),
        subtypes: _cloneDeep(subtypes),
        parameters_loaded: true,
        constructionsLoaded: true
      };
    }

    case actionTypes.setSelected: {
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.payload.name]: action.payload.value
        }
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}

const setConstructions = (constructions) => ({
  type: actionTypes.setConstructions,
  payload: constructions
});

const setActiveConstruction = (constructionId) => ({
  type: actionTypes.setActiveConstruction,
  payload: constructionId
});

const setParameters = (parameters, price, transport) => ({
  type: actionTypes.setParameters,
  payload: {
    parameters,
    price,
    transport
  }
});

const parseToRequest = (selected) => {
  const requestData = {};

  Object.keys(selected).forEach((el) => {
    requestData[`${el}_selected`] = {
      data: {
        id: selected[el],
        type: el
      }
    };
  });

  return requestData;
};

export const getRequestData = (withParameters = true) => (_, getStore) => {
  const appStore = getStore().Construction;
  let requestData = {
    type: 'calculation',
    relationships: {
      construction: {
        data: {
          type: 'construction',
          id: appStore.activeConstruction
        }
      }
    }
  };

  if (withParameters) {
    const selectedData = parseToRequest(appStore.selected);

    requestData = {
      ...requestData,
      relationships: {
        ...requestData.relationships,
        ...selectedData
      }
    };
  }

  return requestData;
};

const sendCalculation = (withParameters = true) => (dispatch) => {
  const requestData = dispatch(getRequestData(withParameters));

  axios.post('calculation', requestData).then(({ data }) => {
    const convertedData = convertCalculationResponse(data);

    dispatch(setParameters(convertedData, data.attributes.price, data.attributes.transport));
  });
};

const getInitialParameters = () => (dispatch) => {
  dispatch(sendCalculation(false));
};

const handleSetSelected = (name, value) => ({
  type: actionTypes.setSelected,
  payload: {
    name,
    value
  }
});

export const resetSettings = () => (dispatch) => {
  dispatch(getInitialParameters());
};

export const setAllColors = (value, isWooden) => (dispatch, getStore) => {
  const store = getStore().Construction;

  colors.forEach((color) => {
    if (store.selected[color]) {
      if (color !== 'roof_color') {
        dispatch(handleSetSelected(color, value));
      } else if (!isWooden) {
        dispatch(handleSetSelected(color, value));
      }
    }
  });

  dispatch(sendCalculation());
};

export const setSelected = (name, value) => (dispatch) => {
  dispatch(handleSetSelected(name, value));
  dispatch(sendCalculation());
};

export const handleConstructionChange = (constructionId) => (dispatch) => {
  dispatch(setActiveConstruction(constructionId));
  dispatch(getInitialParameters());
};

export const loadConfiguration = () => (dispatch) => {
  axios.get('constructions').then(({ data }) => {
    const response = data;
    const defaultConstruction = response.find((el) => el.attributes.is_default);

    dispatch(handleConstructionChange(defaultConstruction.id));
    dispatch(setConstructions(response));
  });
};
