import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import selector from './insideEditor.selector';
import { setSelected } from '../Construction/construction.reducer';

import SelectEdit from '../../components/SelectEdit';

import { availableType } from '../../types/available.types';
import { infoShape } from '../../types/info.types';

class InsideEditor extends React.Component {
    static propTypes = {
      loaded: PropTypes.bool.isRequired,
      setSelected: PropTypes.func.isRequired,
      available: PropTypes.arrayOf(availableType),
      info: infoShape,
      selected: PropTypes.number
    }

    static defaultProps = {
      available: [],
      selected: null,
      info: null
    }

    handleWidthChange = (value) => {
      this.props.setSelected('inside', value);
    }

    render() {
      return (
        this.props.loaded ? (
          <SelectEdit
            selected={this.props.selected}
            loaded={this.props.loaded}
            title={this.props.info.name}
            info={this.props.info}
            onChange={this.handleWidthChange}
            options={this.props.available.map((el) => ({
              id: el.id,
              value: `${el.attributes.value}`
            }))}
          />
        ) : <SelectEdit loading />
      );
    }
}

const mapDispatchToProps = {
  setSelected
};

export default connect(selector, mapDispatchToProps)(InsideEditor);
