import styled from 'styled-components';

const ConstructionsWrapper = styled.div`
  padding: 0 21px 21px 21px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 100%;
`;

const Col = styled.div`
  flex: 0 0 ${(props) => `${props.grid - 1}8%`};
  flex-direction: column;

  @media (max-width: 600px) {
    width: 100%;
    flex: initial;
    padding-left: 0;
    padding-right: 0;
    margin: 0 0 20px;
  }
`;

const Spacer = styled.div`
  height: 16px;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

const BoxContent = styled.div`
  height: 99px;
  padding: 0 20px;
`;

const StatisticsWrapper = styled.div`
  display: flex;
  margin: 18px 24px;
`;

const TransportWrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  margin: 0 0 3px 9px;
`;

const InfoBox = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  padding: 5px 5px;
`;

export {
  Col,
  Container,
  InfoBox,
  StatisticsWrapper,
  Spacer,
  ConstructionsWrapper,
  BoxContent,
  TransportWrapper
};
