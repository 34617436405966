import { createSelector } from 'reselect';

const selectSelector = (state) => state.Select;

export default createSelector(
  selectSelector,
  (select) => ({
    ...select
  })
);
