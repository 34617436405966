import React from 'react';
import { connect } from 'react-redux';

import GateEditor from '../../components/GateEditor';

import selector from './leftGateEditor.selector';

const LeftGateEditor = (props) => (
  <GateEditor {...props} />
);

export default connect(selector, null)(LeftGateEditor);
