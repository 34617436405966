export const colors = {
  main: '#2F3031',
  selectedMain: '#2F3031',
  mainLight: '#f0f2f5',
  mainLighter: '#f0f2f5',
  borderColor: '#dacbcb',
  secondaryBorderColor: '#e9e9e9',
  gray: '#f0f2f5',
  inValidFormColor: '#f5222d',
  arrowColor: '#fff',
  primaryFontColor: '#fff',
  colorContainerBcg: '#fff',
  drawerBackground: '#fff',
  selectArrow: '#000',
  borderDark: '#f0f2f5'
};

export const sider = {
  collapsedWidth: 80,
  width: 400,
  transitionLength: 0.2
};
