import styled from 'styled-components';
import {colors} from "../utils/variables.styles";

const ContainerHeaderWrapper = styled.div`
  margin: 0 0 6px 16px;
  border-radius: 7px;
  background: ${colors.mainLight}
    
  @media (max-width: 640px) {
    margin: 0 16px 6px 16px;
  }
`;

export {
  ContainerHeaderWrapper
};
