import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => {
    let gateImg = null;

    if (construction.available.gate.length > 0) {
      const gateId = construction.selected.gate;

      const gate = construction.available.gate.find((el) => el.id === gateId);
      gateImg = gate.attributes.img;
    }

    let gate_count = null;
    const gate = construction.available.gate_count.find(
      (el) => el.id === construction.selected.gate_count
    );

    if (gate) {
      gate_count = gate.attributes.value;
    }

    return {
      gateImg,
      info: construction.info.gate,
      loaded: construction.parameters_loaded,
      gate_count_available: [...construction.available.gate_count],
      gate_count_selected: construction.selected.gate_count,
      gate_count_info: construction.info.gate_count,
      gate_count
    };
  }
);
