import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Form,
  Statistic,
  Select,
  Icon
} from 'antd';
import Box from '../../components/Box';

import { open } from '../../layout/components/Drawer/Drawer.reducer';
import { setSelected } from '../Construction/construction.reducer';
import selector from './ConstructionFooter.selector';

import {
  Container,
  InfoBox,
  Spacer,
  Col,
  BoxContent,
  TransportWrapper,
  StatisticsWrapper
} from './ConstructionFooter.styles';
import { colors } from '../../utils/variables.styles';

const { Option } = Select;

const ConstructionHeader = ({
  openForm,
  parameters_loaded,
  form,
  voivodeship,
  price,
  transport,
  handleSelect,
  isSticky
}) => {
  const { getFieldDecorator, setFieldsValue } = form;

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err) => {
      if (err) {
        // eslint-disable-next-line no-useless-return
        return;
      }

      openForm(true);
    });
  };

  const changeVoivodeship = (value) => {
    handleSelect('voivodeship', value);
  };

  const handleSelectChange = (value) => {
    setFieldsValue({
      voivodeship: value
    });
    changeVoivodeship(value);
  };

  return (
    <Form hideRequiredMark onSubmit={handleSubmit}>
	<InfoBox>
        Wizualizacja garażu, jest wizualizacją w 3D, przytrzymaj lewym przyciskiem myszy na garażu, by swobodnie obrócić projekt budynku.
      </InfoBox>
      <Container>
        <Spacer />
        <Col grid={4}>
          <Box
            id="select-container"
            clear
            loading={!parameters_loaded}
            loaderHeight={99}
            styles={{ background: colors.main, color: colors.primaryFontColor }}
          >
            <BoxContent>
              <Form.Item label="Województwo" className="select-up">
                {getFieldDecorator('voivodeship', {
                  initialValue: voivodeship.length > 0 && voivodeship.find((el) => el.attributes.name === 'Wybierz województwo').id,
                  rules: [{ required: true, message: 'Proszę wybrać województwo' }]
                })(
                  <Select
                    getPopupContainer={() => document.getElementById('select-container')}
                    dropdownClassName="to-up"
                    onDropdownVisibleChange={(isOpen) => {
                      if (isOpen && !isSticky) {
                        setTimeout(() => {
                          const self = Array.from(document.getElementsByClassName('to-up'))[0];
                          self.style.transform = 'translateY(calc(-100% - 40px))';
                        }, 0);
                      } else if (!isOpen && !isSticky) {
                        setTimeout(() => {
                          const self = Array.from(document.getElementsByClassName('to-up'))[0];
                          self.style.transform = 'translateY(0)';
                        }, 500);
                      }
                    }}
                    suffixIcon={<Icon type="caret-down" fill={colors.selectArrow} className="custom-chevron" />}
                    onChange={handleSelectChange}
                    placeholder="Proszę wybrać województwo"
                  >
                    {
                      voivodeship.map((el) => (
                        <Option key={el.id} value={el.id}>{el.attributes.name}</Option>
                      ))
                    }
                  </Select>,
                )}
              </Form.Item>
            </BoxContent>
          </Box>
          <div style={{ width: 10, height: 1 }} />
        </Col>
        <Col grid={4}>
          <Box
            clear
            loading={price === null}
            loaderHeight={99}
            wide
            styles={{ background: colors.mainLight }}
          >
            <StatisticsWrapper>
              <Statistic title="Cena brutto:" value={price + transport} groupSeparator="." suffix="zł" />
              <TransportWrapper>(całkowity koszt z dowozem i montażem)</TransportWrapper>
            </StatisticsWrapper>
          </Box>
          <div style={{ width: 10, height: 1 }} />
        </Col>
        <Col grid={2}>
          <Button
            htmlType="submit"
            type="primary"
            loading={!parameters_loaded}
            style={{ width: '100%', height: 99, fontSize: 24 }}
          >
            KUP TERAZ
          </Button>
        </Col>
      </Container>
      <InfoBox>
        * Wszytkie zamównienia są weryfikowane telefonicznie przed realizają.
        Przewidywany czas oczekiwania na dostawę to około 2 tygodnie.
      </InfoBox>
    </Form>
  );
};

ConstructionHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
  openForm: PropTypes.func.isRequired,
  isSticky: PropTypes.bool,
  parameters_loaded: PropTypes.bool,
  price: PropTypes.number,
  transport: PropTypes.number,
  voivodeship: PropTypes.arrayOf(PropTypes.object)
};

ConstructionHeader.defaultProps = {
  isSticky: false,
  parameters_loaded: false,
  price: 0,
  transport: 0,
  voivodeship: []
};

const mapDispatchToProps = {
  openForm: open,
  handleSelect: setSelected
};

export default connect(selector, mapDispatchToProps)(Form.create()(ConstructionHeader));
