import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => ({
    price: construction.price,
    transport: construction.transport,
    constructions: construction.constructions,
    activeConstruction: construction.activeConstruction
  })
);
