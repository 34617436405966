import React from 'react';
import PropTypes from 'prop-types';

import {
  IconWrapper,
  Container,
  ContentWrapper,
  OpenableWrapper,
  ArrowDown,
  CollapseWrapper,
  TextWrapper
} from './CollapsibleItem.styles';

const CollapsibleItem = ({
  collapsed,
  icon,
  children,
  onClick,
  pointer,
  openable,
  opened,
  nested,
  subsection
}) => (
  <Container
    pointer={pointer && !collapsed}
    onClick={collapsed ? () => {} : onClick}
    collapsed={collapsed}
    nested={nested}
  >
    <ContentWrapper>
      {
        icon ? (
          <IconWrapper collapsed={collapsed}>{icon}</IconWrapper>
        ) : null
      }
      <CollapseWrapper collapsed={collapsed}>
        <TextWrapper subsection={subsection}>
          {children}
        </TextWrapper>
      </CollapseWrapper>
    </ContentWrapper>
    {
      openable ? (
        <CollapseWrapper collapsed={collapsed}>
          <OpenableWrapper opened={opened}>
            <ArrowDown subsection={subsection} />
          </OpenableWrapper>
        </CollapseWrapper>
      ) : null
    }
  </Container>
);

CollapsibleItem.propTypes = {
  children: PropTypes.node.isRequired,
  collapsed: PropTypes.bool.isRequired,
  icon: PropTypes.node,
  nested: PropTypes.bool,
  openable: PropTypes.bool,
  opened: PropTypes.bool,
  pointer: PropTypes.bool,
  onClick: PropTypes.func
};

CollapsibleItem.defaultProps = {
  icon: null,
  nested: false,
  openable: false,
  opened: false,
  pointer: false,
  onClick: () => {}
};

export default CollapsibleItem;
