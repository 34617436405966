import * as THREE from 'three';
import OrbitControls from 'three-orbitcontrols';

const initScene = (mount) => {
  const width = mount.clientWidth;
  const height = mount.clientHeight;

  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(width, height);

  const camera = new THREE.PerspectiveCamera(
    window.innerWidth < 500 ? window.innerWidth / 10 : 50,
    width / height,
    0.01,
    1000
  );

  camera.setRotationFromEuler(new THREE.Euler(0.56, -0.9, -0.46));
  camera.position.setX(-16);
  camera.position.setY(6.8);
  camera.position.setZ(9.4);

  const pointLight = new THREE.PointLight(0xffffff, 0.8);
  camera.add(pointLight);

  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xf0f2f5);

  const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
  scene.add(ambientLight);
  scene.add(camera);

  mount.appendChild(renderer.domElement);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableKeys = false;
  controls.enablePan = false;
  controls.enableZoom = true;
  controls.autoRotate = true;
  controls.autoRotateSpeed = 0.4;
  controls.minDistance = 10;
  controls.minPolarAngle = Math.PI / 4;
  controls.maxPolarAngle = Math.PI / 2;
  controls.enabled = window.innerWidth > 1000;
  const clock = new THREE.Clock();

  return {
    renderer,
    camera,
    scene,
    controls,
    clock
  };
};

export default initScene;
