import styled, { css } from 'styled-components';

import { colors } from '../../utils/variables.styles';

const StyledBox = styled.div`
    max-width: 100%;
    border: 1px solid ${colors.borderColor};
    border-radius: 7px;
    overflow: hidden;

    ${(props) => props.styles && css`
      background: ${props.styles.background};
      .ant-form-item-label>label {
        color: ${props.styles.color}!important;
      }
    `}

    ${(props) => !props.clear && css`
      padding: 24px;
    `}

    ${(props) => props.wide && css`
      width: 100%;
    `}

    ${(props) => props.center && css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

    ${(props) => props.pointer && css`
      cursor: pointer;
    `}

    ${(props) => props.active && css`
      border: 1px solid ${colors.main};
    `}

    ${(props) => props.loading && css`
      text-align: center;
      background: rgba(0,0,0,0.05);
    `}

    ${(props) => props.loaderHeight && css`
      min-height: ${props.loaderHeight}px;
    `}
`;

export default StyledBox;
