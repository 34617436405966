import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import 'antd/dist/antd.min.css';
import '../src/styles/main.scss';

import App from './layout/App';

import './utils/axios.config';
import rootReducer from './containers/reducers';

const middlewareEnhancer = applyMiddleware(thunkMiddleware);

const store = createStore(rootReducer, undefined, composeWithDevTools(middlewareEnhancer));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('__calculator_root__')
);
