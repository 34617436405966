import styled, { css } from 'styled-components';

import { colors, sider } from '../../../utils/variables.styles';

const SiderContainer = styled.div`
  width: ${sider.width}px;
  max-width: 100vw;
  background: ${colors.colorContainerBcg};
  transition: ${sider.transitionLength}s;

  ${(props) => props.collapsed && css`
    width: ${sider.collapsedWidth}px;
  `}
`;

export {
  SiderContainer
};
