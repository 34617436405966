import React from 'react';
import { connect } from 'react-redux';

import GateEditor from '../../components/GateEditor';

import selector from './rightGateEditor.selector';

const RightGateEditor = (props) => (
  <GateEditor {...props} />
);

export default connect(selector, null)(RightGateEditor);
