import { combineReducers } from 'redux';

import Construction from './Construction/construction.reducer';
import Drawer from '../layout/components/Drawer/Drawer.reducer';
import Select from '../components/SelectEdit/SelectEdit.reducer';

export default combineReducers({
  Construction,
  Drawer,
  Select
});
