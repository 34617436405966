import React from 'react';
import PropTypes from 'prop-types';

import Collapsible from 'react-collapsible';
import CollapsibleItem from '../CollapsibleItem';

import { Container, CollapsibleWraper } from './EditableSection.styles';

import { colors } from '../../utils/variables.styles';

class EditableSection extends React.Component {
  static propTypes = {
    collapsed: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    icon: PropTypes.node,
    nested: PropTypes.bool
  }

  static defaultProps = {
    children: null,
    nested: false,
    icon: null,
    subsection: false
  }

  state = {
    opened: true
  }

  onOpening = () => {
    this.setState({ opened: true });
  }

  toggleOpen = () => {
    this.setState((prevState) => ({ opened: !prevState.opened }));
  }

  renderTrigger = () => (
    <Container
      background={colors.mainLight}
      overflow={this.props.nested ? 18 : 0}
    >
      <CollapsibleItem
        collapsed={this.props.collapsed}
        openable={!!this.props.children}
        icon={this.props.icon}
        pointer={!!this.props.children}
        opened={this.state.opened}
        nested={this.props.nested}
        subsection={this.props.subsection}
      >
        {this.props.title}
      </CollapsibleItem>
    </Container>
  )

  render() {
    return (
      <CollapsibleWraper>
        <Collapsible
          trigger={this.renderTrigger()}
          transitionTime={150}
          onOpening={this.toggleOpen}
          onClosing={this.toggleOpen}
          open
        >
          {
            !this.props.collapsed ? (
              <Container
                background={colors.mainLighter}
                overflow={this.props.nested ? 28 : 0}
              >
                {this.props.children}
              </Container>
            ) : null
          }
        </Collapsible>
      </CollapsibleWraper>
    );
  }
}

export default EditableSection;
