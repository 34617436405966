import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => ({
    loaded: construction.parameters_loaded,
    available: [...construction.available.gate_right],
    info: construction.info.gate_right,
    selected: construction.selected.gate_right,
    height_available: [...construction.available.gate_right_height],
    height_selected: construction.selected.gate_right_height,
    height_info: construction.info.gate_right_height,
    width_available: [...construction.available.gate_right_width],
    width_selected: construction.selected.gate_right_width,
    width_info: construction.info.gate_right_width,
    ramp_width_available: [...construction.available.gate_ramp_right_width],
    ramp_width_selected: construction.selected.gate_ramp_right_width,
    ramp_width_info: construction.info.gate_ramp_right_width,
    ramp_height_available: [...construction.available.gate_ramp_right_height],
    ramp_height_selected: construction.selected.gate_ramp_right_height,
    ramp_height_info: construction.info.gate_ramp_right_height
  })
);
