import styled, { css } from 'styled-components';

import { colors, sider } from '../../utils/variables.styles';

const Container = styled.div`
  background: ${colors.main};
  border-radius: 7px;
  margin: 8px 0px 8px 0px;
  z-index: 3;
  bottom: 0;
  max-width: 100vw;
  width: ${sider.width}px;
  transition: width ${sider.transitionLength}s;

  ${(props) => props.collapsed && css`
    width: ${sider.collapsedWidth}px;
  `};

  @media (max-width: 1000px) {
    position: relative;
    margin-top: 16px;
  }
`;

export {
  Container
};
