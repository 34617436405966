import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import selector from './secondHeightOption.selector';

import SelectEdit from '../../components/SelectEdit';

import { availableType } from '../../types/available.types';
import { infoShape } from '../../types/info.types';

const SecondHeightOption = ({
  loaded,
  available,
  selected,
  info
}) => (
  loaded ? (
    <SelectEdit
      selected={selected}
      loaded={loaded}
      title={info.name}
      info={info}
      options={available.map((el) => ({
        id: el.id,
        value: `${el.attributes.value_cm}cm`
      }))}
    />
  ) : <SelectEdit loading />
);

SecondHeightOption.propTypes = {
  loaded: PropTypes.bool.isRequired,
  available: PropTypes.arrayOf(availableType),
  info: infoShape,
  selected: PropTypes.number
};

SecondHeightOption.defaultProps = {
  available: [],
  selected: null,
  info: null
};

export default connect(selector, null)(SecondHeightOption);
