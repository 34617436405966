import styled from 'styled-components';

const SceneWrapper = styled.div`
  canvas {
    max-width: 100%;
    transition: width .2s;
  };
`;

export { SceneWrapper };
