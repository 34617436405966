import styled, { css } from 'styled-components';
import { colors } from '../../utils/variables.styles';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.mainLight};

  ${(props) => props.nested && css`
    background: ${colors.mainLighter};
  `}
`;

export {
  Container
};
