import styled from 'styled-components';

const ConstructionsWrapper = styled.div`
  padding: 0 21px 21px 21px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
`;

export {
  Container,
  ConstructionsWrapper
};
