import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  IconWrapper
} from './SiderAction.styles';

const SiderAction = ({
  collapsed,
  icon,
  children,
  pointer,
  onClick,
  bottom
}) => (
  <Container
    collapsed={collapsed}
    pointer={pointer}
    onClick={onClick}
    bottom={bottom}
  >
    <IconWrapper collapsed={collapsed}>
      {icon}
    </IconWrapper>
    {
      !collapsed ? (
        children
      ) : null
    }
  </Container>
);

SiderAction.propTypes = {
  children: PropTypes.node.isRequired,
  collapsed: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
  bottom: PropTypes.bool,
  pointer: PropTypes.bool,
  onClick: PropTypes.func
};

SiderAction.defaultProps = {
  bottom: false,
  pointer: false,
  onClick: () => {}
};

export default SiderAction;
