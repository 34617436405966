import _forEach from 'lodash/forEach';

const getSelectedId = (relationships, parameterName) => {
  const selectedParameter = relationships[`${parameterName}_selected`];
  let parameterId = null;

  if (selectedParameter && selectedParameter.data && selectedParameter.data.id) {
    parameterId = selectedParameter.data.id;
  }

  return parameterId;
};

const convertPreAvailable = (preAvailable, parameters) => {
  const available = {};

  _forEach(preAvailable, (el, key) => {
    if (!available[key]) {
      available[key] = [];
    }

    _forEach(el, (el1) => {
      available[key].push({
        id: el1.id,
        attributes: parameters[key][el1.id]
      });
    });
  });

  return available;
};

export const colors = [
  'walls_color',
  'roof_color',
  'gates_color',
  'tooling_color',
  'doors_front_color',
  'doors_back_color',
  'doors_left_color',
  'doors_right_color'
];

const convertCalculationResponse = (data) => {
  const parameters = {};
  const preAvailable = {};
  const selected = {};
  const info = {};
  const subtypes = {};

  data.included.forEach((el) => {
    if (!parameters[el.type]) {
      parameters[el.type] = {};
    }
    parameters[el.type][el.id] = el.attributes;

    if (!selected[el.type]) {
      selected[el.type] = getSelectedId(data.relationships, el.type);
    }

    const preAvailableItem = data.relationships[`${el.type}_available`];

    if (el.subtype) {
      if (!subtypes[el.subtype]) {
        subtypes[el.subtype] = [];
      }

      if (!subtypes[el.subtype].find((el1) => el1.id === el.id)) {
        subtypes[el.subtype].push({
          id: el.id,
          ...el.attributes
        });
      }
    }

    if (!preAvailable[el.type] && preAvailableItem) {
      info[el.type] = preAvailableItem.meta;
      preAvailable[el.type] = preAvailableItem.data;
    }
  });

  const available = convertPreAvailable(preAvailable, parameters);

  _forEach(subtypes, (subtype, key) => {
    if (!parameters[key]) {
      parameters[key] = {};
    }
    parameters[key][subtype.id] = subtype;

    if (!selected[key]) {
      selected[key] = getSelectedId(data.relationships, key);
    }

    if (!available[key]) {
      available[key] = subtype;
    }

    const preAvailableItem = data.relationships[`${key}_available`];

    if (preAvailableItem) {
      info[key] = preAvailableItem.meta;
    }
  });

  available.colors = [
    ...available.wooden,
    ...available.matt,
    ...available.smooth
  ];

  _forEach(colors, (el) => {
    selected[el] = data.relationships[`${el}_selected`] && data.relationships[`${el}_selected`].data.id;
    info[el] = data.relationships[`${el}_available`] && data.relationships[`${el}_available`].meta;
  });

  return {
    parameters,
    available,
    selected,
    info,
    subtypes
  };
};

export default convertCalculationResponse;
