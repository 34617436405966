import { createSelector } from 'reselect';

const constructionSelector = (state) => state.Construction;

export default createSelector(
  constructionSelector,
  (construction) => ({
    loaded: construction.parameters_loaded,
    door_back_count_available: [...construction.available.door_back_count],
    door_back_count_info: construction.info.door_back_count,
    door_back_count_selected: construction.selected.door_back_count,
    door_back_size_available: [...construction.available.door_back_size],
    door_back_size_info: construction.info.door_back_size,
    door_back_size_selected: construction.selected.door_back_size,
    door_front_count_available: [...construction.available.door_front_count],
    door_front_count_info: construction.info.door_front_count,
    door_front_count_selected: construction.selected.door_front_count,
    door_front_size_available: [...construction.available.door_front_size],
    door_front_size_info: construction.info.door_front_size,
    door_front_size_selected: construction.selected.door_front_size,
    door_left_count_available: [...construction.available.door_left_count],
    door_left_count_info: construction.info.door_left_count,
    door_left_count_selected: construction.selected.door_left_count,
    door_left_size_available: [...construction.available.door_left_size],
    door_left_size_info: construction.info.door_left_size,
    door_left_size_selected: construction.selected.door_left_size,
    door_right_count_available: [...construction.available.door_right_count],
    door_right_count_info: construction.info.door_right_count,
    door_right_count_selected: construction.selected.door_right_count,
    door_right_size_available: [...construction.available.door_right_size],
    door_right_size_info: construction.info.door_right_size,
    door_right_size_selected: construction.selected.door_right_size
  })
);
