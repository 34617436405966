import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import selector from './scene.selector';
import Scene from '../../components/Scene';

import { colors } from '../../utils/variables.styles';

const SceneWrapper = styled.div`
  height: calc(85vh - 118px);
  border: 1px solid ${colors.borderColor};
  border-radius: 7px;
  cursor: move;
  background: ${colors.mainLight};
  padding-left: 5px;
  margin-top: 10px;
  
  @media (max-width: 500px) {
    height: 202px;
  }

  @media (max-width: 1000px) {
    margin-bottom: 24px;
  }
`;

const SceneContainer = (props) => (
  <SceneWrapper>
    {
      props.constructionsLoaded ? (
        <Scene key={props.activeConstruction} {...props} />
      ) : 'Loading models...'
    }
  </SceneWrapper>
);

export default connect(selector, null)(SceneContainer);
